<script>
export default {
  data() {
    return {
      autoFillCondition: false,
      autoFillAddress: false,
      autoFillPhone: false,
    };
  },
  watch: {
    patient: {
      handler() {
        if (this.patient && this.patient[this.autoFillCondition]) {
          if (
            this.autoFillAddress &&
            this.patient.address &&
            !this.patient.relatedpersons[this.relatedPersonIndex].address
          ) {
            this.patient.relatedpersons[this.relatedPersonIndex].address =
              this.patient.address;
          }

          if (
            this.autoFillPhone &&
            !this.patient.relatedpersons[this.relatedPersonIndex].phone
          ) {
            this.patient.relatedpersons[this.relatedPersonIndex].phone =
              this.patient.phone;
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    relatedPersonIndex() {
      for (let index = 0; index < this.patient.relatedpersons.length; index++) {
        const relatedPerson = this.patient.relatedpersons[index];
        if (relatedPerson.type === this.type) {
          return index;
        }
      }
      return -1;
    },
  },
  methods: {
    checkIndexExistance() {
      if (this.relatedPersonIndex == -1) {
        this.patient.relatedpersons.push({ type: this.type });
      }
    },
  },
  created() {
    this.checkIndexExistance();
  },
};
</script>